import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { useToast } from "@/hooks/use-toast"
import { signInWithMagicLink, signOut } from "@/utils/supabase"
import { PaperPlaneIcon } from "@radix-ui/react-icons"
import { useSession } from "@supabase/auth-helpers-react"
import { useState } from "react"

export function SignInButton() {
  const session = useSession()
  const { toast } = useToast()
  const [email, setEmail] = useState("")

  async function handleClickSignIn() {
    if (session?.user.email) await signOut()

    await signInWithMagicLink(email)

    toast({
      className: "bg-green-100 text-green-900 font-bold",
      title: "✅ We've just sent you a magic link. Please check your e-mail.",
    })
  }

  return (
    <div className="flex gap-2">
      <Popover>
        <PopoverTrigger asChild>
          <Button onClick={() => session?.user.email && signOut()}>
            {session?.user.email ? "Sign out" : "Sign in"}
          </Button>
        </PopoverTrigger>

        {!session?.user.email && (
          <PopoverContent className="flex flex-col gap-3 p-4">
            <Input
              type="email"
              className="text-center"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="E-mail"
              autoComplete="email"
              name="email"
            />
            <Button
              onClick={handleClickSignIn}
              className="flex items-center gap-2"
            >
              <PaperPlaneIcon /> Send magic link
            </Button>
          </PopoverContent>
        )}
      </Popover>
    </div>
  )
}
