import { SignInButton } from "@/components/landing-page/sign-in-button"

export function Header() {
  return (
    <div className="flex items-center justify-between">
      <h1 className="text-2xl font-bold">epicscript</h1>

      <SignInButton />
    </div>
  )
}
