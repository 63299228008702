import { EmailForm } from "@/components/landing-page/email-form"
import { Header } from "@/components/landing-page/header"

export default function HomePage() {
  return (
    <main className="flex min-h-screen flex-col items-center justify-center bg-gradient-to-b from-neutral-100 to-neutral-200 p-8 dark:from-neutral-900 dark:to-neutral-800">
      {(process.env.NODE_ENV === "development" ||
        process.env.NEXT_PUBLIC_VERCEL_ENV === "preview") && <Header />}

      <div className="w-full max-w-2xl space-y-12 text-center">
        <h1 className="font-geist-sans bg-gradient-to-r from-indigo-500 to-purple-600 bg-clip-text text-5xl font-bold tracking-tight text-transparent md:text-6xl">
          epicscript
          <span className="mt-4 block text-2xl font-normal text-neutral-700 dark:text-neutral-300 md:text-3xl">
            A new way of writing scripts for your videos
          </span>
        </h1>

        <EmailForm />
      </div>
    </main>
  )
}
