import { useToast } from "@/hooks/use-toast"
import { api } from "@/utils/api"
import { useState } from "react"

function SubmitButton({ isPending }: { isPending: boolean }) {
  return (
    <button
      type="submit"
      disabled={isPending}
      className="font-geist-sans rounded-lg bg-indigo-600 px-6 py-3 font-medium text-white shadow-lg transition-all duration-200 hover:-translate-y-0.5 hover:bg-indigo-500 hover:shadow-indigo-500/25 disabled:opacity-50"
    >
      {isPending ? "Joining..." : "Join the waitlist"}
    </button>
  )
}

export function EmailForm() {
  const [error, setError] = useState<string | null>(null)
  const { toast } = useToast()

  const { mutate: submitEmail, isPending } = api.waitlist.join.useMutation({
    onSuccess: () => {
      setError(null)
      toast({
        className: "bg-green-100 text-green-900 font-bold",
        title: "✅ Thanks for joining! We'll keep you updated.",
      })
    },
    onError: (err) => {
      setError(err.message)
    },
  })

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setError(null)

    const formData = new FormData(e.currentTarget)
    const email = formData.get("email") as string

    submitEmail({ email })
    e.currentTarget.reset()
  }

  return (
    <form
      onSubmit={handleSubmit}
      className="mx-auto mt-8 flex max-w-md flex-col gap-4 sm:flex-row"
    >
      <input
        name="email"
        type="email"
        placeholder="Your best e-mail"
        className="font-geist-sans flex-1 rounded-lg border border-neutral-300 bg-white/80 px-4 py-3 shadow-sm backdrop-blur-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 dark:border-neutral-700 dark:bg-black/30"
        required
      />
      <SubmitButton isPending={isPending} />
      {error && <p className="text-sm text-red-500">{error}</p>}
    </form>
  )
}
